var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.stillLoading ? _c('div', [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Loading..."
    }
  })], 1) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.stillLoading,
      expression: "!stillLoading"
    }]
  }, [_c('b-progress', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showProgress,
      expression: "showProgress"
    }],
    attrs: {
      "value": _vm.counterProgress,
      "max": 100,
      "show-progress": "",
      "animated": ""
    }
  }), _c('b-col', {
    attrs: {
      "sm": "12",
      "tag": "div"
    }
  }, [_c('b', [_c('div', {
    staticClass: "mt-4",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('strong', {
    staticClass: "text-2xl"
  }, [_vm._v(" Form " + _vm._s(_vm.$route.meta.label) + " ")])]), _c('b-row', {
    attrs: {
      "tag": "div"
    }
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "tag": "div"
    }
  }, [_c('vue-dropzone', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showProfileLogo,
      expression: "showProfileLogo"
    }],
    ref: "dropzoneLogo",
    staticClass: "m-dropzone dropzone",
    attrs: {
      "id": "dropzone-logo",
      "options": _vm.dropzoneLogoOptions
    },
    on: {
      "vdropzone-success": _vm.dropzoneLogoSuccess,
      "vdropzone-removed-file": _vm.dropzoneRemovedSuccess
    }
  }), _c('div', {
    staticClass: "shadow-card rounded-lg bg-white p-6 mb-6 mt-6 w-7/10"
  }, _vm._l(_vm.columns, function (item) {
    return _c('b-form-group', {
      key: item.field,
      attrs: {
        "breakpoint": "sm"
      }
    }, [_c('label', {
      staticStyle: {
        "color": "black"
      },
      attrs: {
        "for": "name"
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]), item.field == 'url' ? _c('img', {
      attrs: {
        "src": _vm.options[item.field]
      }
    }) : item.field == 'tugas_description' ? _c('div', [_c('quill-editor', {
      model: {
        value: _vm.options[item.field],
        callback: function callback($$v) {
          _vm.$set(_vm.options, item.field, $$v);
        },
        expression: "options[item.field]"
      }
    })], 1) : item.field == 'name' ? _c('b-form-input', {
      attrs: {
        "placeholder": 'Enter ' + item.label + ' ...',
        "type": "text",
        "readonly": "true",
        "disabled": item.field == 'id' || item.field == 'created_date' || item.field == 'updated_date'
      },
      model: {
        value: _vm.options[item.field],
        callback: function callback($$v) {
          _vm.$set(_vm.options, item.field, $$v);
        },
        expression: "options[item.field]"
      }
    }) : item.field === 'deliveryTypes' ? _c('div', {}, [_c('ul', {
      staticClass: "class-type"
    }, [_c('li', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.options[item.field],
        expression: "options[item.field]"
      }],
      staticClass: "styled-checkbox",
      attrs: {
        "type": "checkbox",
        "id": "webinar",
        "value": "WEBINAR"
      },
      domProps: {
        "checked": Array.isArray(_vm.options[item.field]) ? _vm._i(_vm.options[item.field], "WEBINAR") > -1 : _vm.options[item.field]
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.options[item.field],
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = "WEBINAR",
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.options, item.field, $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.options, item.field, $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.options, item.field, $$c);
          }
        }
      }
    }), _c('label', {
      attrs: {
        "for": "webinar"
      }
    }, [_vm._v("Webinar")])]), _c('li', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.options[item.field],
        expression: "options[item.field]"
      }],
      staticClass: "styled-checkbox",
      attrs: {
        "type": "checkbox",
        "id": "self-based",
        "value": "SELF_BASED"
      },
      domProps: {
        "checked": Array.isArray(_vm.options[item.field]) ? _vm._i(_vm.options[item.field], "SELF_BASED") > -1 : _vm.options[item.field]
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.options[item.field],
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = "SELF_BASED",
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.options, item.field, $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.options, item.field, $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.options, item.field, $$c);
          }
        }
      }
    }), _c('label', {
      attrs: {
        "for": "self-based"
      }
    }, [_vm._v("Self-based")])]), _c('li', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.options[item.field],
        expression: "options[item.field]"
      }],
      staticClass: "styled-checkbox",
      attrs: {
        "type": "checkbox",
        "id": "offline",
        "value": "OFFLINE"
      },
      domProps: {
        "checked": Array.isArray(_vm.options[item.field]) ? _vm._i(_vm.options[item.field], "OFFLINE") > -1 : _vm.options[item.field]
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.options[item.field],
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = "OFFLINE",
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.options, item.field, $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.options, item.field, $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.options, item.field, $$c);
          }
        }
      }
    }), _c('label', {
      attrs: {
        "for": "offline"
      }
    }, [_vm._v("Offline")])]), _c('li', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.options[item.field],
        expression: "options[item.field]"
      }],
      staticClass: "styled-checkbox",
      attrs: {
        "type": "checkbox",
        "id": "blended",
        "value": "BLENDED"
      },
      domProps: {
        "checked": Array.isArray(_vm.options[item.field]) ? _vm._i(_vm.options[item.field], "BLENDED") > -1 : _vm.options[item.field]
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.options[item.field],
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = "BLENDED",
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.options, item.field, $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.options, item.field, $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.options, item.field, $$c);
          }
        }
      }
    }), _c('label', {
      attrs: {
        "for": "blended"
      }
    }, [_vm._v("Blended")])])])]) : item.field == 'exam_url_array' ? _c('b-form-group', [_c('b-button', {
      staticStyle: {
        "margin-left": "10px"
      },
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": _vm.addExamUrlArray
      }
    }, [_c('i', {
      staticClass: "fa fa-plus-square"
    }), _vm._v("Tambah Exam Url ")]), _vm._l(_vm.options[item.field], function (itemExamUrl, indexExamUrl) {
      return _c('div', {
        key: 'idx' + indexExamUrl
      }, [_c('b-button', {
        attrs: {
          "variant": "warning"
        },
        on: {
          "click": function click($event) {
            return _vm.deleteExamUrlArray(indexExamUrl);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-minus-square"
      })]), _c('label', {
        staticStyle: {
          "color": "black"
        }
      }, [_vm._v(" ExamUrl ke-" + _vm._s(indexExamUrl + 1) + " ")]), _c('b-form-input', {
        attrs: {
          "placeholder": 'Enter ' + item.label + ' ...',
          "type": "text"
        },
        model: {
          value: _vm.options[item.field][indexExamUrl],
          callback: function callback($$v) {
            _vm.$set(_vm.options[item.field], indexExamUrl, $$v);
          },
          expression: "options[item.field][indexExamUrl]"
        }
      })], 1);
    })], 2) : item.field == 'quiz_url_array' ? _c('b-form-group', [_c('b-button', {
      staticStyle: {
        "margin-left": "10px"
      },
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": _vm.addQuizUrlArray
      }
    }, [_c('i', {
      staticClass: "fa fa-plus-square"
    }), _vm._v("Tambah Quiz Url ")]), _vm._l(_vm.options[item.field], function (itemQuizUrl, indexQuizUrl) {
      return _c('div', {
        key: 'quiz-' + indexQuizUrl
      }, [_c('b-button', {
        attrs: {
          "variant": "warning"
        },
        on: {
          "click": function click($event) {
            return _vm.deleteQuizUrlArray(indexQuizUrl);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-minus-square"
      })]), _c('label', {
        staticStyle: {
          "color": "black"
        }
      }, [_vm._v(" QuizUrl ke-" + _vm._s(indexQuizUrl + 1) + " ")]), _c('b-form-input', {
        attrs: {
          "placeholder": 'Enter ' + item.label + ' ...',
          "type": "text"
        },
        model: {
          value: _vm.options[item.field][indexQuizUrl],
          callback: function callback($$v) {
            _vm.$set(_vm.options[item.field], indexQuizUrl, $$v);
          },
          expression: "options[item.field][indexQuizUrl]"
        }
      })], 1);
    })], 2) : item.field == 'quiz_exercise_id_array' ? _c('b-form-group', [_c('div', {
      staticClass: "w-1/2"
    }, [_c('b-button', {
      staticStyle: {
        "margin-bottom": "16px"
      },
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": _vm.addQuizExerciseIdArray
      }
    }, [_c('i', {
      staticClass: "fa fa-plus-square"
    }), _vm._v("Tambah Quiz Exercise ")]), _vm._l(_vm.options[item.field], function (itemQuizExerciseId, indexQuizExerciseId) {
      return _c('div', {
        key: 'quiz-' + indexQuizExerciseId,
        staticClass: "border p-2 mb-2 rounded"
      }, [_c('b-button', {
        attrs: {
          "variant": "warning"
        },
        on: {
          "click": function click($event) {
            return _vm.deleteQuizExerciseIdArray(indexQuizExerciseId);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-minus-square"
      })]), _c('label', {
        staticStyle: {
          "color": "black",
          "margin-left": "8px"
        }
      }, [_vm._v(" Quiz Exercise " + _vm._s(indexQuizExerciseId + 1) + " ")]), _c('div', {
        staticClass: "mt-2"
      }, [_c('select', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.options[item.field][indexQuizExerciseId],
          expression: "options[item.field][indexQuizExerciseId]"
        }],
        staticClass: "form-control m-input",
        attrs: {
          "placeholder": 'Enter ' + item.label + ' ...'
        },
        on: {
          "change": function change($event) {
            var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
              return o.selected;
            }).map(function (o) {
              var val = "_value" in o ? o._value : o.value;
              return val;
            });

            _vm.$set(_vm.options[item.field], indexQuizExerciseId, $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
          }
        }
      }, [_c('option', {
        attrs: {
          "value": "0",
          "selected": "selected"
        }
      }, [_vm._v("ID Exercise Quiz")]), _vm._l(_vm.list_exercise_quiz, function (item_exercise) {
        return _c('option', {
          key: item_exercise.id,
          domProps: {
            "value": item_exercise.id
          }
        }, [_vm._v(_vm._s(item_exercise.name))]);
      })], 2)])], 1);
    })], 2)]) : item.field == 'java_instructur_id_array' ? _c('b-form-group', [_c('div', {
      staticClass: "w-1/2"
    }, [_c('b-button', {
      staticStyle: {
        "margin-bottom": "16px"
      },
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": _vm.addJavaInstructurIdArray
      }
    }, [_c('i', {
      staticClass: "fa fa-plus-square"
    }), _vm._v("Tambah Instruktur ")]), _vm._l(_vm.options[item.field], function (itemJavaInstructurId, indexJavaInstructurId) {
      return _c('div', {
        key: 'instructor-' + indexJavaInstructurId,
        staticClass: "border p-2 mb-2 rounded"
      }, [_c('b-button', {
        attrs: {
          "variant": "warning"
        },
        on: {
          "click": function click($event) {
            return _vm.deleteJavaInstructurIdArray(indexJavaInstructurId);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-minus-square"
      })]), _c('label', {
        staticStyle: {
          "color": "black",
          "margin-left": "8px"
        }
      }, [_vm._v(" Instruktur " + _vm._s(indexJavaInstructurId + 1) + " ")]), _c('div', {
        staticClass: "mt-2"
      }, [_c('select', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.options[item.field][indexJavaInstructurId],
          expression: "options[item.field][indexJavaInstructurId]"
        }],
        staticClass: "form-control m-input",
        on: {
          "change": function change($event) {
            var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
              return o.selected;
            }).map(function (o) {
              var val = "_value" in o ? o._value : o.value;
              return val;
            });

            _vm.$set(_vm.options[item.field], indexJavaInstructurId, $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
          }
        }
      }, _vm._l(_vm.list_instructur, function (item, index) {
        return _c('option', {
          key: 'instructor-' + index,
          domProps: {
            "value": item.id
          }
        }, [_vm._v(_vm._s(item.name))]);
      }), 0)])], 1);
    })], 2)]) : item.field == 'pretest_url_array' ? _c('b-form-group', [_c('b-button', {
      staticStyle: {
        "margin-left": "10px"
      },
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": _vm.addPretestUrlArray
      }
    }, [_c('i', {
      staticClass: "fa fa-plus-square"
    }), _vm._v("Tambah Pretest Url ")]), _c('label', {
      staticStyle: {
        "color": "black"
      }
    }, [_vm._v(" ExamUrl ke-" + _vm._s(_vm.indexExamUrl + 1) + " ")]), _c('b-form-input', {
      attrs: {
        "placeholder": 'Enter ' + item.label + ' ...',
        "type": "text"
      },
      model: {
        value: _vm.options[item.field][_vm.indexExamUrl],
        callback: function callback($$v) {
          _vm.$set(_vm.options[item.field], _vm.indexExamUrl, $$v);
        },
        expression: "options[item.field][indexExamUrl]"
      }
    })], 1) : item.field == 'quiz_url_array' ? _c('b-form-group', [_c('b-button', {
      staticStyle: {
        "margin-left": "10px"
      },
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": _vm.addQuizUrlArray
      }
    }, [_c('i', {
      staticClass: "fa fa-plus-square"
    }), _vm._v("Tambah Quiz Url ")]), _vm._l(_vm.options[item.field], function (itemQuizUrl, indexQuizUrl) {
      return _c('div', {
        key: 'quizitem-' + indexQuizUrl
      }, [_c('b-button', {
        attrs: {
          "variant": "warning"
        },
        on: {
          "click": function click($event) {
            return _vm.deleteQuizUrlArray(indexQuizUrl);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-minus-square"
      })]), _c('label', {
        staticStyle: {
          "color": "black"
        }
      }, [_vm._v(" QuizUrl ke-" + _vm._s(indexQuizUrl + 1) + " ")]), _c('b-form-input', {
        attrs: {
          "placeholder": 'Enter ' + item.label + ' ...',
          "type": "text"
        },
        model: {
          value: _vm.options[item.field][indexQuizUrl],
          callback: function callback($$v) {
            _vm.$set(_vm.options[item.field], indexQuizUrl, $$v);
          },
          expression: "options[item.field][indexQuizUrl]"
        }
      })], 1);
    })], 2) : item.field == 'quiz_exercise_id_array' ? _c('b-form-group', [_c('b-button', {
      staticStyle: {
        "margin-left": "10px"
      },
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": _vm.addQuizExerciseIdArray
      }
    }, [_c('i', {
      staticClass: "fa fa-plus-square"
    }), _vm._v("Tambah Quiz Exercise Id ")]), _vm._l(_vm.options[item.field], function (itemQuizExerciseId, indexQuizExerciseId) {
      return _c('div', [_c('b-button', {
        attrs: {
          "variant": "warning"
        },
        on: {
          "click": function click($event) {
            return _vm.deleteQuizUrlArray(indexQuizExerciseId);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-minus-square"
      })]), _c('label', {
        staticStyle: {
          "color": "black"
        }
      }, [_vm._v(" Quiz Exercise Id ke-" + _vm._s(indexQuizExerciseId + 1) + " ")]), _c('select', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.options[item.field][indexQuizExerciseId],
          expression: "options[item.field][indexQuizExerciseId]"
        }],
        staticClass: "form-control m-input",
        attrs: {
          "placeholder": 'Enter ' + item.label + ' ...'
        },
        on: {
          "change": function change($event) {
            var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
              return o.selected;
            }).map(function (o) {
              var val = "_value" in o ? o._value : o.value;
              return val;
            });

            _vm.$set(_vm.options[item.field], indexQuizExerciseId, $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
          }
        }
      }, [_c('option', {
        attrs: {
          "value": "0",
          "selected": "selected"
        }
      }, [_vm._v("ID Exercise Quiz")]), _vm._l(_vm.list_exercise_quiz, function (item_exercise, index_exercise) {
        return _c('option', {
          key: item_exercise.id,
          domProps: {
            "value": item_exercise.id
          }
        }, [_vm._v(_vm._s(item_exercise.name))]);
      })], 2)], 1);
    })], 2) : item.field == 'pretest_url_array' ? _c('b-form-group', [_c('b-button', {
      staticStyle: {
        "margin-left": "10px"
      },
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": _vm.addPretestUrlArray
      }
    }, [_c('i', {
      staticClass: "fa fa-plus-square"
    }), _vm._v("Tambah Pretest Url ")]), _vm._l(_vm.options[item.field], function (itemPretestUrl, indexPretestUrl) {
      return _c('div', [_c('b-button', {
        attrs: {
          "variant": "warning"
        },
        on: {
          "click": function click($event) {
            return _vm.deletePretestUrlArray(indexPretestUrl);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-minus-square"
      })]), _c('label', {
        staticStyle: {
          "color": "black"
        }
      }, [_vm._v(" Pretest Url ke-" + _vm._s(indexPretestUrl + 1) + " ")]), _c('b-form-input', {
        attrs: {
          "placeholder": 'Enter ' + item.label + ' ...',
          "type": "text"
        },
        model: {
          value: _vm.options[item.field][indexPretestUrl],
          callback: function callback($$v) {
            _vm.$set(_vm.options[item.field], indexPretestUrl, $$v);
          },
          expression: "options[item.field][indexPretestUrl]"
        }
      })], 1);
    })], 2) : item.field == 'video_url_array' ? _c('b-form-group', [_c('b-button', {
      staticStyle: {
        "margin-bottom": "16px"
      },
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": _vm.addVideoUrlArray
      }
    }, [_c('i', {
      staticClass: "fa fa-plus-square"
    }), _vm._v("Tambah Video Url ")]), _c('div', {
      staticClass: "w-1/2"
    }, _vm._l(_vm.options[item.field], function (itemVideoUrl, indexVideoUrl) {
      return _c('div', {
        staticClass: "border p-2 mb-2 rounded"
      }, [_c('b-button', {
        attrs: {
          "variant": "warning"
        },
        on: {
          "click": function click($event) {
            return _vm.deleteVideoUrlArray(indexVideoUrl);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-minus-square"
      })]), _c('label', {
        staticStyle: {
          "color": "black",
          "margin-left": "8px"
        }
      }, [_vm._v(" Video Url " + _vm._s(indexVideoUrl + 1) + " ")]), _c('div', {
        staticClass: "mt-2"
      }, [_c('b-form-input', {
        attrs: {
          "placeholder": 'Enter ' + item.label + ' ...',
          "type": "text"
        },
        model: {
          value: _vm.options[item.field][indexVideoUrl],
          callback: function callback($$v) {
            _vm.$set(_vm.options[item.field], indexVideoUrl, $$v);
          },
          expression: "options[item.field][indexVideoUrl]"
        }
      })], 1)], 1);
    }), 0)], 1) : item.field == 'pretest_exercise_id' ? _c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.options[item.field],
        expression: "options[item.field]"
      }],
      staticClass: "form-control m-input",
      attrs: {
        "placeholder": 'Enter ' + item.label + ' ...'
      },
      on: {
        "change": function change($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });

          _vm.$set(_vm.options, item.field, $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }
      }
    }, [_c('option', {
      attrs: {
        "value": "0",
        "selected": "selected"
      }
    }, [_vm._v("ID Exercise Pretest")]), _vm._l(_vm.list_exercise_pretest, function (item_exercise, index_exercise) {
      return _c('option', {
        key: item_exercise.id,
        domProps: {
          "value": item_exercise.id
        }
      }, [_vm._v(_vm._s(item_exercise.name))]);
    })], 2) : item.field == 'quiz1_exercise_id' ? _c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.options[item.field],
        expression: "options[item.field]"
      }],
      staticClass: "form-control m-input",
      attrs: {
        "placeholder": 'Enter ' + item.label + ' ...'
      },
      on: {
        "change": function change($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });

          _vm.$set(_vm.options, item.field, $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }
      }
    }, [_c('option', {
      attrs: {
        "value": "0",
        "selected": "selected"
      }
    }, [_vm._v("ID Quiz 1 Pretest")]), _vm._l(_vm.list_exercise_quiz, function (item_exercise, index_exercise) {
      return _c('option', {
        key: item_exercise.id,
        domProps: {
          "value": item_exercise.id
        }
      }, [_vm._v(_vm._s(item_exercise.name))]);
    })], 2) : item.field == 'quiz2_exercise_id' ? _c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.options[item.field],
        expression: "options[item.field]"
      }],
      staticClass: "form-control m-input",
      attrs: {
        "placeholder": 'Enter ' + item.label + ' ...'
      },
      on: {
        "change": function change($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });

          _vm.$set(_vm.options, item.field, $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }
      }
    }, [_c('option', {
      attrs: {
        "value": "0",
        "selected": "selected"
      }
    }, [_vm._v("ID Quiz 2 Pretest")]), _vm._l(_vm.list_exercise_quiz, function (item_exercise, index_exercise) {
      return _c('option', {
        key: item_exercise.id,
        domProps: {
          "value": item_exercise.id
        }
      }, [_vm._v(_vm._s(item_exercise.name))]);
    })], 2) : item.field == 'quiz3_exercise_id' ? _c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.options[item.field],
        expression: "options[item.field]"
      }],
      staticClass: "form-control m-input",
      attrs: {
        "placeholder": 'Enter ' + item.label + ' ...'
      },
      on: {
        "change": function change($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });

          _vm.$set(_vm.options, item.field, $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }
      }
    }, [_c('option', {
      attrs: {
        "value": "0",
        "selected": "selected"
      }
    }, [_vm._v("ID Quizn3 Pretest")]), _vm._l(_vm.list_exercise_quiz, function (item_exercise, index_exercise) {
      return _c('option', {
        key: item_exercise.id,
        domProps: {
          "value": item_exercise.id
        }
      }, [_vm._v(_vm._s(item_exercise.name))]);
    })], 2) : item.field == 'exam_exercise_id' ? _c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.options[item.field],
        expression: "options[item.field]"
      }],
      staticClass: "form-control m-input",
      attrs: {
        "placeholder": 'Enter ' + item.label + ' ...'
      },
      on: {
        "change": function change($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });

          _vm.$set(_vm.options, item.field, $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }
      }
    }, [_c('option', {
      attrs: {
        "value": "0",
        "selected": "selected"
      }
    }, [_vm._v("ID Exam Pretest")]), _vm._l(_vm.list_exercise_exam, function (item_exercise, index_exercise) {
      return _c('option', {
        key: item_exercise.id,
        domProps: {
          "value": item_exercise.id
        }
      }, [_vm._v(_vm._s(item_exercise.name))]);
    })], 2) : _c('TextField', {
      attrs: {
        "type": "text",
        "borderEnabled": "",
        "placeholder": 'Enter ' + item.label + ' ...',
        "disabled": item.field == 'id' || item.field == 'created_date' || item.field == 'updated_date'
      },
      model: {
        value: _vm.options[item.field],
        callback: function callback($$v) {
          _vm.$set(_vm.options, item.field, $$v);
        },
        expression: "options[item.field]"
      }
    })], 1);
  }), 1)], 1)], 1), _c('div', {
    staticClass: "mb-6 text-left w-7/10"
  }, [(_vm.$store.getters['customer/customer'].role_id = 1) ? _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.submitData
    }
  }, [_c('i', {
    staticClass: "fa fa-dot-circle-o"
  }), _vm._v(" Submit ")]) : _vm._e()], 1)], 1)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }